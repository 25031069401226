<template>
  <div>
    <v-row>
      <v-col md="6">
        <v-tabs v-model="tab" grow>
          <v-tab> Tópicos </v-tab>
          <v-tab> Videos </v-tab>
        </v-tabs>
      </v-col>
      <v-col class="d-flex justify-end align-end">
        <v-btn @click="getHelp()" color="success">
          <v-icon class="mr-1">mdi-whatsapp</v-icon> Enviar Mensagem
        </v-btn>
      </v-col>
    </v-row>
    <br />
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in topics"
                  :key="i"
                  @click="openTopic(item.path)"
                >
                  <v-icon small color="primary"> mdi-circle </v-icon>
                  <v-list-item-icon> </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col
            md="4"
            cols="12"
            v-for="(video, index) in getVideos()"
            :key="index"
          >
            <div class="container">
              <iframe
                class="responsive-iframe"
                v-if="video.id.kind == 'youtube#video'"
                :src="`https://www.youtube.com/embed/${video.id.videoId}`"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";
import { customerAlias } from "@/utils/customerAlias";
export default {
  data() {
    return {
      tab: null,
      videos: {
        items: [],
      },
      topics: [
        {
          title: `Veja como validar seu ${customerAlias()} no whatsapp para receber nossas notificações.`,
          path: "/ajuda/validar-whatsapp",
        },
      ],
    };
  },

  created() {
    this.getSupportInVideo();
  },

  methods: {
    getVideos() {
      return this.videos.items.filter((row) => {
        return row.id.kind == "youtube#video";
      });
    },

    getSupportInVideo() {
      axios
        .get(
          `https://www.googleapis.com/youtube/v3/search?key=${process.env.VUE_APP_YOUTUBE_API_KEY}&channelId=${process.env.VUE_APP_SUPPORT_CHANNEL_ID}&order=date`
        )
        .then((response) => {
          this.videos = response.data;
        })
        .catch((error) => {});
    },
    getHelp() {
      let link = `https://api.whatsapp.com/send?phone=+${process.env.VUE_APP_SUPPORT_WHATSAPP}`;

      let msg = "&text=Olá, Preciso de ajuda!";

      window.open(link + msg);
    },
    openTopic(path) {
      window.open(path, "_blank");
    },
  },
};
</script>



<style   scoped>
.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
